import { posthogCaptureWithUserContext } from "./general";

/**
 * Captures the event the user clicks a link to register.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 */
export function posthogRegistrationLinkClicked({ triggerId }) {
  posthogCaptureWithUserContext("registration_link_clicked", {
    trigger_id: triggerId,
  });
}

/**
 * Captures the event the user chooses an account type during
 * registration.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {string} properties.accountType The account type chosen.
 */
export function posthogRegistrationAccountTypeChosen({
  triggerId,
  accountType,
}) {
  posthogCaptureWithUserContext("registration_account_type_chosen", {
    trigger_id: triggerId,
    account_type: accountType,
  });
}

/**
 * Captures the event the user arrives at the registration page
 * using a direct class join URL.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {string} properties.classId The ID of the class.
 * @param {string} properties.className The name of the class.
 */
export function posthogRegistrationClassJoinUrlLoaded({
  triggerId,
  classId,
  className,
}) {
  posthogCaptureWithUserContext("registration_class_join_url_loaded", {
    trigger_id: triggerId,
    class_id: classId,
    class_name: className,
  });
}

/**
 * Captures the event the user chooses a school during the registration
 * process.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {{ sid: number, name: string }} properties.school The school chosen.
 * @param {{ query: string, results: number, position: number }} properties.search Details of search.
 * @param {string} properties.accountType The account type chosen.
 */
export function posthogRegistrationSchoolChosen({ triggerId, school, search, accountType }) {
  const { sid, name } = school ?? {};
  const { query, results, position } = search ?? {};
  posthogCaptureWithUserContext("registration_school_chosen", {
    trigger_id: triggerId,
    chosen_school_id: sid,
    chosen_school_name: name,
    school_search_query: query,
    school_search_results: results,
    school_search_position: position,
    account_type: accountType,
  });
}

/**
 * Captures the event the user chooses to continue registration without
 * selecting a school.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {string} properties.accountType The account type chosen.
 */
export function posthogRegistrationWithoutSchoolChosen({ triggerId, accountType }) {
  posthogCaptureWithUserContext("registration_without_school_chosen", {
    trigger_id: triggerId,
    account_type: accountType,
  });
}

/**
 * Captures the event the user signifies that they cannot find their
 * school.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {{ query: string }} properties.search Details of search.
 * @param {string} properties.accountType The account type chosen.
 */
export function posthogRegistrationSchoolNotFound({ triggerId, search, accountType }) {
  const { query } = search ?? {};
  posthogCaptureWithUserContext("registration_school_not_found", {
    trigger_id: triggerId,
    school_search_query: query,
    account_type: accountType,
  });
}

/**
 * Captures the event the user clicks the link to register a new
 * school.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {string} properties.accountType The account type chosen.
 */
export function posthogRegistrationNewSchoolLinkClicked({ triggerId, accountType }) {
  posthogCaptureWithUserContext("registration_new_school_link_clicked", {
    trigger_id: triggerId,
    account_type: accountType,
  });
}

/**
 * Captures the event the user successfully completes registration.
 *
 * @param {Object} properties
 * @param {string} properties.triggerId The ID of the trigger.
 * @param {string} properties.method The registration method used.
 * @param {Object} properties.user The new user object.
 * @param {number} properties.user.uid The ID of the new user.
 * @param {string} properties.user.status The status of the new user.
 * @param {string} properties.user.type The account type of the new user.
 * @param {{ sid: number, name: string } | undefined} properties.school The school of the new user.
 * @param {number} properties.newsletter The newsletter status of the new user (0 = "Yes" or 1 = "No").
 */
export function posthogRegistrationCompleted({ triggerId, method, user, school, newsletter }) {
  const { uid, status, type } = user ?? {};
  const { sid, name } = school ?? {};
  posthogCaptureWithUserContext("registration_completed", {
    trigger_id: triggerId,
    registration_method: method,
    new_account_id: uid,
    new_account_status: status,
    new_account_type: type,
    new_account_school_id: sid,
    new_account_school_name: name,
    new_account_newsletter: newsletter === 0,
  });
}
